import {
	white,
	black,
	ghost,
	lightestGrey,
	lighterGrey,
	grey,
	darkGrey,
	darkerGrey,
	darkestGrey,
	darkAlt,
	fadedSlate,
	slate,
	darkSlate,
	darkerSlate,
	gold,
	darkGold,
	darkGreen,
	darkerGreen,
	lightGreen,
	lighterGreen,
	red,
} from "../../styles"

// Style-Guide Color Data
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const colorData = [
	{
		colorName: "Black",
		hex: `${black}`,
	},
	{
		colorName: "White",
		hex: `${white}`,
	},
	{
		colorName: "Ghost",
		hex: `${ghost}`,
	},
	{
		colorName: "Lightest Grey",
		hex: `${lightestGrey}`,
	},
	{
		colorName: "Lighter Grey",
		hex: `${lighterGrey}`,
	},
	{
		colorName: "Grey",
		hex: `${grey}`,
	},
	{
		colorName: "Dark Grey",
		hex: `${darkGrey}`,
	},
	{
		colorName: "Darker Grey",
		hex: `${darkerGrey}`,
	},
	{
		colorName: "Darkest Grey",
		hex: `${darkestGrey}`,
	},
	{
		colorName: "Dark Alternative",
		hex: `${darkAlt}`,
	},
	{
		colorName: "Slate",
		hex: `${slate}`,
	},
	{
		colorName: "Faded Slate",
		hex: `${fadedSlate}`,
	},
	{
		colorName: "Dark Slate",
		hex: `${darkSlate}`,
	},
	{
		colorName: "Darker Slate",
		hex: `${darkerSlate}`,
	},
	{
		colorName: "Lighter Green",
		hex: `${lighterGreen}`,
	},
	{
		colorName: "Light Green",
		hex: `${lightGreen}`,
	},
	{
		colorName: "Dark Green",
		hex: `${darkGreen}`,
	},
	{
		colorName: "Darker Green",
		hex: `${darkerGreen}`,
	},
	{
		colorName: "Gold",
		hex: `${gold}`,
	},
	{
		colorName: "Dark Gold",
		hex: `${darkGold}`,
	},
	{
		colorName: "Red",
		hex: `${red}`,
	},
]
