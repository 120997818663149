import React from "react"
import styled from "styled-components"
import { Container, SectionHeading, SubHeading } from "../../styles"

export default function Headings() {
	return (
		<Container modifiers={["section"]}>
			<StyledSectionHeading modifiers={["center"]}>Headings</StyledSectionHeading>
			<h1 className="page-heading">H1 Page Heading</h1>
			<SectionHeading>H2 Section Heading</SectionHeading>
			<SubHeading> H3 Sub Heading</SubHeading>
		</Container>
	)
}

const StyledSectionHeading = styled(SectionHeading)`
	margin-top: 100px;
`
