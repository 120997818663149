import React from "react"
import Layout_main from "../layouts/Layout_main.js"
import StyleGuideHeader from "../components/style-guide/SGHeader.js"
import Headings from "../components/style-guide/SGHeadings.js"
import Colors from "../components/style-guide/SGColors.js"
import Logos from "../components/style-guide/SGLogos.js"

function StyleGuide({ location }) {
	return (
		<Layout_main location={location}>
			<StyleGuideHeader />
			<Headings />
			<Colors />
			<Logos />
		</Layout_main>
	)
}

export default StyleGuide
