import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import { BrandLogo } from "../../assets/logos"
import { elevation, Container, SectionHeading } from "../../styles"

const logos = [
	{
		name: "logo",
		title: "Icon",
	},
]

function Logos({ data }) {
	return (
		<Container modifiers={["section", "nested"]}>
			<SectionHeading modifiers={["center"]}>Logos</SectionHeading>
			<GridContainer block>
				{logos.map((logo, i) => (
					<LogoCard key={i}>
						<BrandLogo width={300} height={300} name={logo.name} />
						<p>{logo.title}</p>
					</LogoCard>
				))}
				<LogoCard>
					{/* This powers your email logo */}
					<GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="logo" />
					<p>Logo</p>
				</LogoCard>
			</GridContainer>
		</Container>
	)
}

export default function SFLogos() {
	return (
		<StaticQuery
			query={graphql`
				query LOGO_QUERY {
					logo: file(relativePath: { regex: "/logo.png/" }) {
						childImageSharp {
							gatsbyImageData(width: 96, height: 96, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
						}
					}
				}
			`}
			render={data => <Logos data={data} />}
		/>
	)
}

const GridContainer = styled(Container)`
	display: grid;
	grid-template-columns: repeat(auto-fill, 400px);
	grid-auto-rows: 420px;
	grid-gap: 20px;
	justify-content: center;

	svg {
		margin-top: 40px;
	}

	img {
		width: 96px;
		height: 96px;
	}
`

const LogoCard = styled.div`
	display: grid;
	justify-content: center;
	border-radius: 5px;
	background: #cfcfcf;
	${elevation[3]};
`
