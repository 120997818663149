import React from "react"
import styled from "styled-components"
import { colorData } from "./SGColorData"
import { elevation, Container, SectionHeading } from "../../styles"

function Colors(props) {
	return (
		<Container modifiers={["section", "nested"]}>
			<SectionHeading modifiers={["center"]}>Brand Colors</SectionHeading>
			<GridContainer block>
				{colorData.map((color, i) => (
					<SwatchCard key={i}>
						<SwatchColor style={{ background: `${color.hex}` }} />
						<SwatchLabel>{`${color.colorName} ${color.hex}`}</SwatchLabel>
					</SwatchCard>
				))}
			</GridContainer>
		</Container>
	)
}

const GridContainer = styled(Container)`
	display: grid;
	grid-template-columns: repeat(auto-fill, 235px);
	grid-auto-rows: 235px;
	grid-gap: 20px;
	justify-content: center;
`

const SwatchCard = styled.div`
	border-radius: 5px;
	${elevation[3]};
`

const SwatchColor = styled.div`
	border-radius: 5px 5px 0 0;
	width: 100%;
	height: 175px;
`

const SwatchLabel = styled.div`
	padding: 8px 12px;
`

export default Colors
